import React from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = ({ bannerImg, pageClass }) => {
  const { width } = useWindowSize()
  return (
    <ImageWrapper
      width={width}
      image={bannerImg}
      loading="eager"
      alt="Hero Image"
      className={pageClass}
    />
  )
}
export default HeroBanner
const ImageWrapper = styled(GatsbyImage)`
  width: ${props => props.width}px;
  height: ${props => props.width / 2}px;
  margin-top: 123px;
  &.home {
    margin-top: 0px;
  }
  &.history {
    margin-top: 0px;
  }
  &.single__offer {
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    &.single__room__page {
      margin-top: 0px;
    }
    &.stay__page {
      margin-top: 0px;
    }
    &.dine__drinks {
      margin-top: 88px;
    }
  }
`
