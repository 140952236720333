import React, { useEffect, useRef, useState } from "react"
import BackToTop from "../../components/BackToTop"
import BookingOnline from "../../components/BookingOnline"
import Aos from "aos"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import useWindowSize from "../../../hooks/useWindowSize"
import StayBookingOnline from "../../components/StayBooking"
import Seo from "../../components/Seo"
import styled from "styled-components"
import _ from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"
import CommonLink from "../../components/CommonLink"

const StayPage = ({ data }) => {
  const { width } = useWindowSize()
  const thumRef = useRef(null)

  const [swidth, setSWidth] = useState("auto")

  const getListSize = () => {
    const newWidth = thumRef.current.clientWidth
    setSWidth(newWidth)
  }

  useEffect(() => {
    getListSize()
  }, [width])
  useEffect(() => {
    window.addEventListener("resize", getListSize)
  }, [])

  const { heroImg, title, _rawDescription, rooms } = data?.sanityPages?.stayPage

  useEffect(() => {
    Aos.init()
  })
  return (
    <>
      <Seo
        title={"Stay Page"}
        description={
          "All available room's in hotel.Categorized accoding to it's features."
        }
      />
      <Layout>
        {width > 767 && (
          <div className="header_bottom">
            <div className="container">
              <StayBookingOnline />
            </div>
          </div>
        )}
        <BackToTop />
        <HeroBanner
          bannerImg={heroImg?.asset?.gatsbyImageData}
          pageClass="stay__page"
        />
        {width < 767 && (
          <div className="action__section">
            <div className="container">
              <BookingOnline />
            </div>
          </div>
        )}
        <div className="stay__section stay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="stay_title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <h3>{title}</h3>
                  <PortableText value={_rawDescription} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rooms__area stay">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="rooms__title">
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    {roomsCategory.category_title}
                  </h3>
                  {roomsCategory.categoryList.map((item, index) => (
                    <div
                      key={index}
                      className="category__type"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1100"
                      onClick={() => {
                        setCategory(item.category_name)
                      }}
                    >
                      {item.category_name}
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="room_blk">
                  <div className="row">
                    {rooms?.map((item, index) => (
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          key={index}
                          className="rooms_wrap"
                          data-aos="fade-up"
                          data-aos-delay="500"
                          data-aos-duration="1900"
                        >
                          <CommonLink
                            link={`/stay/${item?.id}`}
                            type="internal"
                          >
                            <div ref={thumRef}>
                              <ImageWrapper
                                width={swidth}
                                image={item?.thumbnail?.asset?.gatsbyImageData}
                                className={"rooms_thumb__bg stay"}
                                alt="Room Thumb"
                              />
                            </div>
                          </CommonLink>
                          <div className="rooms_text stay mt-2">
                            <div>
                              <CommonLink
                                link={`/stay/${item?.id}`}
                                type="internal"
                              >
                                <h4>{item?.name}</h4>
                              </CommonLink>
                              <span>{item?.features} </span>
                            </div>
                            <div className="rooms__details">
                              <CommonLink
                                link={`/stay/${item?.id}`}
                                type="internal"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                VIEW DETAILS
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6"
                                  height="9"
                                  viewBox="0 0 6 9"
                                  fill="none"
                                  style={{ marginLeft: 5 }}
                                >
                                  <path
                                    d="M1 1L5 4.5L1 8"
                                    stroke="#002643"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </CommonLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default StayPage
const ImageWrapper = styled(GatsbyImage)`
  width: ${props => props.width}px;
  height: ${props => props.width / 1.5}px;
  margin-top: 20px;
`
export const singleroomsData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      stayPage {
        title
        _rawDescription
        heroImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        rooms {
          id
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          name
          features
        }
      }
    }
  }
`
