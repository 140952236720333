import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const StayBookingOnline = () => {
  const stayBookingData = useStaticQuery(graphql`
    query STayBookingQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "stayBookOnline" } } }
      ) {
        edges {
          node {
            frontmatter {
              bookingLogo {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              onlineBookingButton {
                url
                title
              }
            }
          }
        }
      }
    }
  `)
  const { bookingLogo, onlineBookingButton } =
    stayBookingData.allMarkdownRemark.edges[0].node.frontmatter
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="action_blk">
          <div className="action_text_img">
            <a href="#">
              <img src={bookingLogo.childImageSharp.fluid.src} alt="" />
            </a>
          </div>
          <div className="action_btn">
            <a href={onlineBookingButton.url} target="_blank">
              {onlineBookingButton.title}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StayBookingOnline
